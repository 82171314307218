import React from 'react';
import { ArrowRight, FacebookLogo, PronostixLogo, TwitterLogo } from '../assets/icons';
import { MarketingFonts } from '../assets/fonts/Marketing';
import MarketingNav from '../components/MarketingNav';
import MarketingFooter from '../components/MarketingFooter';
// import { pathInstagramLogo } from '../assets/icons/InstagramLogo';

/*const FancyInstagramLogo = ({ className }: { className: string }) => {
  return (
    <span className="group" aria-hidden={true}>
      <svg width={256} height={256} viewBox="0 0 256 256" className={`${className} group-hover:hidden`}>
        <path d={pathInstagramLogo} fill="currentColor" />
      </svg>
      <svg
        width={256}
        height={256}
        stroke="url(#brand-gradient)"
        viewBox="0 0 256 256"
        fill="url(#brand-gradient)"
        className={`${className} hidden group-hover:block`}
      >
        <defs>
          <linearGradient id="brand-gradient" x1="0%" y1="0%" x2="100%" y2="50%">
            <stop offset="0%" stopColor="#405de6" />
            <stop offset="50%" stopColor="#833ab4" />
            <stop offset="100%" stopColor="#e1306c" />
          </linearGradient>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#b794f4', stopOpacity: 1 }} />
            <stop offset="50%" style={{ stopColor: '#ed64a6', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#f56565', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={pathInstagramLogo} />
      </svg>
    </span>
  );
};*/

const ContactUsBlock = () => {
  return (
    <div className="grid gap-10  md:grid-cols-2 md:gap-44 mb-16 mt-8">
      <div className="flex flex-col space-y-4 items-start">
        <PronostixLogo className="h-4 text-blue-500" />
        <div className="flex space-x-4">
          <a href="https://www.facebook.com/pronostixWC" target="_blank" rel="noreferrer noopener">
            <FacebookLogo className="h-4 w-4 hover:text-[#4267B2]" />
            <span className="sr-only">Facebook</span>
          </a>
          <a href="https://twitter.com/PronostixCM" target="_blank" rel="noreferrer noopener">
            <TwitterLogo className="h-4 w-4 hover:text-[#1DA1F2]" />
            <span className="sr-only">Twitter</span>
          </a>
          {/*<Link href="#">
            <a>
              <FancyInstagramLogo className="h-4 w-4" />
              <span className="sr-only">Instagram</span>
            </a>
          </Link>*/}
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        <span className="font-bold text-lg">Envie de nous dire bonjour 👋 ?</span>
        <span>Que ce soit pour nous soumettre une idée ou prendre un café, n’hésite pas à nous contacter.</span>
        <a href="mailto:contact@pronostix.io" className="text-blue-500 hover:text-blue-700 flex items-center">
          Nous contacter <ArrowRight className="ml-2" aria-hidden={true} />
        </a>
      </div>
    </div>
  );
};

export const MarketingLayout: React.FC = ({ children }) => {
  return (
    <div className="font-market flex flex-col text-black min-h-screen max-w-screen">
      <MarketingFonts />
      <div className="mx-auto flex-1 flex flex-col max-w-[1280px]">
        <MarketingNav />
        <main className="mx-auto max-w-[1280px]">
          {children}
          <div className="mx-4 lg:mx-8">
            <ContactUsBlock />
          </div>
        </main>
      </div>
      <MarketingFooter />
    </div>
  );
};
