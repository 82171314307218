import Link from 'next/link';
import React from 'react';

const FooterLink = ({ href, label }: { href: string; label: string }) => {
  return (
    <Link href={href}>
      <a className="text-black hover:text-gray-500">{label}</a>
    </Link>
  );
};

const LinkSeparator = () => {
  return (
    <span className="text-gray-300">
      <svg viewBox="0 0 10 10" className="h-2 w-2">
        <circle cx="5" cy="5" r="3" fill="currentColor" />
      </svg>
    </span>
  );
};

export default function Footer() {
  return (
    <footer className="bg-gray-100 space-y-4 py-4 md:py-8 lg:py-10 flex flex-col items-center lg:space-y-6">
      <div className="flex flex-col items-center md:flex-row space-x-2">
        <FooterLink label="Politique de confidentialité" href="/privacy" />
        <LinkSeparator />
        <FooterLink label="Mentions légales" href="/terms" />
      </div>
      <div className="text-gray-400 flex">
        © 2021 Pronostix - Made with{'   '}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mx-1 text-blue-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
            clipRule="evenodd"
          />
        </svg>
        {'   '}
        in France
      </div>
    </footer>
  );
}
