import Link from 'next/link';
import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { PronostixLogo } from '../../assets/icons';
import Heart from '../../assets/icons/Heart';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const NavLink = ({ label, href }: { label: string; href: string }) => {
  return (
    <Link href={href}>
      <a className="py-1 lg:px-4 font-bold text-gray-500 hover:text-gray-700 focus:text-gray-700">{label}</a>
    </Link>
  );
};

const NavSupportUs = () => {
  return (
    <Link href="/support-us">
      <a className="flex text-[#FD6EB3] hover:text-[#fd4ca1] focus:text-[#fd4ca1] font-bold mr-2">
        <Heart className="h-4 w-4 mr-2 mt-0.5" aria-hidden={true} />
        Nous soutenir
      </a>
    </Link>
  );
};

const NavBurger: React.FC = ({ children }) => {
  return (
    <div className="w-full max-w-sm px-4 lg:hidden">
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button className="absolute top-3 right-4">
              <span className="sr-only">nav poppup</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              show={open}
            >
              <Popover.Panel static className="absolute z-10 top-full w-screen left-0 px-0">
                <div className="fixed z-0 h-screen w-screen bg-opacity-20 bg-black" />
                <div className="overflow-hidden rounded-b-lg shadow-lg ">
                  <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">{children}</div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default function Nav() {
  const { user } = useCurrentUser();
  return (
    <nav className="flex bg-white p-3 items-center relative w-full">
      <div className="flex-1 cursor-pointer">
        <Link href="/">
          <PronostixLogo className="h-5 text-blue-500" />
        </Link>
      </div>

      <NavBurger>
        <NavSupportUs />
        <NavLink label="Récompenses" href="/rewards" />
        {user ? (
          <Link href="/app">
            <a className="py-3 px-6 text-white font-bold bg-blue-500 hover:bg-blue-600 rounded-lg">
              Accéder à l'application
            </a>
          </Link>
        ) : (
          <>
            <NavLink label="Se connecter" href="/login" />
            <Link href="/login">
              <a className="py-3 px-6 text-white font-bold bg-blue-500 hover:bg-blue-600 rounded-lg">
                S’inscrire gratuitement
              </a>
            </Link>
          </>
        )}
      </NavBurger>

      <div className="items-center font-bold hidden lg:flex">
        <NavSupportUs />
        {user ? (
          <>
            <NavLink label="Récompenses" href="/rewards" />
            <Link href="/app">
              <a className="py-3 px-6 text-white font-bold bg-blue-500 hover:bg-blue-600 rounded-lg">
                Accéder à l'application
              </a>
            </Link>
          </>
        ) : (
          <>
            <div className="divide-x divide-gray-400 mr-2">
              <NavLink label="Récompenses" href="/rewards" />
              <NavLink label="Se connecter" href="/login" />
            </div>
            <Link href="/login">
              <a className="py-3 px-6 text-white font-bold bg-blue-500 hover:bg-blue-600 rounded-lg">
                S’inscrire gratuitement
              </a>
            </Link>
          </>
        )}
      </div>
    </nav>
  );
}
